.btn-enroll {
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.5rem 2rem;
    border-radius: 0rem;
}

#masterclass, #series, #training, #advisory {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: transparent;
    }
    .card-img {
        width: 96px;
        height: 96px;
        padding: 1.5rem;
        background-color: $background-color-blue;
        border-radius: 50%;
        text-align: center;
        margin: 0rem auto;

        .card-img-top {
            height: 100%;
            width: 100%;
        }
    }
    .card-body {
        padding: 1rem 0rem;
        text-align: center;

        .card-title {
            color: #0C2842;
            font-weight: 600;
        }
        .card-text { color: #575757; }
    }
}

#masterclass {
    .requirements {
        background-color: #15284112;
        padding: 3rem;
        margin-bottom: 1.5rem;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;

        h4 {
            font-weight: 600;
            font-size: 18px;
            text-align: center;
            margin-bottom: 1rem;
        }

        li {
            margin-bottom: 1rem;
        }
    }
}

#series, #advisory {
    background-color: #15284112;
}

#faqs {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .accordion {
        .card {
            border-radius: 0rem;
            border-width: 0rem;
            margin-bottom: 1rem;
        }
        .card-header {
            background-color: #0C2842;
            color: #FFF;
            border-bottom: 0rem;
            position: relative;
            cursor: pointer;

            .card-heading {
                padding-right: 3rem;
                display: block;
            }
            .card-icon {
                position: absolute;
                text-align: center;
                background-color: #ADADAD;
                width: 3.5rem;
                height: 100%;
                top: 0rem;
                right: 0rem;
                font-size: 1.5rem;
            }

            .fa-chevron {
                position: relative;
                display: block;
                top: 50%;
                transform: translateY(-50%);
            }
            .fa-chevron::before {
                content: "\f077";
            }
            &.collapsed .fa-chevron::before {
                content: "\f078";
            }
        }
        .card-body {
            padding: 1rem 0rem;
        }
    }
}