$nav-link-color: #0C2842;
$nav-hover-color: #999999;

#navigation-top {
    padding-top: 1rem;
    .nav-item {
        margin: 0.5rem 0.25rem;
    }
    .nav-link.btn {
        border-radius: 0rem;
        padding: 0.25rem 0.5rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
    }
    .nav-link.icon-social {
        width: 32px;
        height: 32px;
        padding: 0.25rem;
        border-radius: 50%;
    }
}

#navigation {
    background-color: #FFFFFF;

    .navbar-brand .img { height: 80px; }

    .navbar-nav {
        .nav-item { display: flex; align-items: center; margin-left: 1.25rem; }
        .nav-link { text-transform: uppercase; font-size: 18px; font-weight: 600; color: $nav-link-color; }
        .nav-link:focus,
        .nav-link:hover { color: $nav-hover-color; }
        .nav-link._active { border-bottom: 2px solid $nav-link-color; }
        .nav-link.active:hover { border-bottom-color: $nav-hover-color; }
    }
}