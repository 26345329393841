#contact {
    padding-top: 3rem;
    padding-bottom: 3rem;

    form {
        .form-control {
            font-style: italic;
            border-radius: 0rem;
        }
        .btn-action {
            border-radius: 0rem;
            text-transform: uppercase;
            font-weight: 600;
            padding: 0.25rem 2rem;
            float: right;
        }
    }

    .widget-content {
        .address a {
            color: #000;
        }
    }
}