#our-goal {
    .card {
        border-radius: 0rem;
        border-width: 0rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: transparent;
    }
    .card-img {
        width: 64px;
        height: 64px;
        padding: 1rem;
        background-color: $background-color-blue;
        border-radius: 50%;
        text-align: center;

        .card-img-top {
            height: 100%;
            width: 100%;
        }
    }
    .card-body {
        padding: 1rem 0rem;

        .card-title {
            color: #0C2842;
            font-weight: 600;
            text-transform: uppercase;
        }
        .card-text { color: #575757; }
    }
}

#our-story {
    background-color: $background-color-blue;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #FFF;
}

#our-team {
    background-color: #0C284212;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        background-color: transparent;
    }
    .card-title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
    }
    .card-subtitle {
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
    }
    .card-link {
        color: #000;
        position: relative;

        .fa {
            width: 20px;
            height: 20px;
            background-color: #000;
            border-radius: 50%;
            color: #FFF;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            position: absolute;
            left: -30px;
            top: 1px;
        }
    }
    .card-text {
        margin-top: 1.5rem;
    }
    .card-body {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}