
@-webkit-keyframes zoomIn {
    from {
        -webkit-transform: scale(1, 1);
    }
    to {
        -webkit-transform: scale(1.5, 1.5);
    }
}
@keyframes zoomIn {
    from {
        transform: scale(1, 1);
    }
    to {
        transform: scale(1.5, 1.5);
    }
}

.carousel {
    ._carousel-inner .carousel-item > img {
        -webkit-animation: zoomIn 30s;
        animation: zoomIn 30s;
    }
}