$btn-padding: 2px 30px;

.btn-golden {
    background-color: #A7957B;
    color: #FFFFFF;
    text-shadow: 0px 3px 12px #00000029;
    border: 0px;
    border-radius: 0px;
    text-transform: uppercase;
    padding: $btn-padding;

    &:focus, &:hover { background-color: #FFFFFF; color: #A7957B; }
}

.btn-outline-golden {
    color: #A7957B;
    border-color: #A7957B;
    border-radius: 0rem;
    text-transform: uppercase;
    padding: $btn-padding;

    &:focus, &:hover { background-color: #A7957B; color: #FFFFFF; }
}

.btn-outline-white {
    color: #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 0rem;
    text-transform: uppercase;
    padding: $btn-padding;

    &:focus, &:hover { background-color: #FFFFFF; color: #A7957B; }
}

.btn-outline-black {
    color: #000000;
    border-color: #000000;
    border-radius: 0rem;
    text-transform: uppercase;
    padding: $btn-padding;

    &:focus, &:hover { background-color: #000000; color: #FFFFFF; }
}