$footer-background-color: #152841;
$footer-text-color: #FFFFFF;
$footer-border-color: #ADADAD;

.widget {
    .widget-title {
        h4 {
            font-size: 18px;
            font-weight: 600;
        }
    }
    .widget-divider::after {
        content: "";
        display: block;
        border-bottom: 2px solid #ADADAD;
        width: 120px;
        margin: 1.5rem 0rem;
    }
    .widget-content {
        .btn-more {
            text-transform: uppercase;
            border-radius: 0rem;
            font-size: 16px;
            font-weight: 600;
            margin-top: 1.5rem;
            background-color: #ADADAD;
            border-color: #ADADAD;
        }
        .address {
            a {
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }
}

#footer {
    background-color: $footer-background-color;
    padding-top: 40px;
    padding-bottom: 30px;
    color: $footer-text-color;

    .nav {
        .nav-link {
            margin-right: 0.8rem;

            &.btn {
                border-radius: 0rem;
                padding: 0.25rem 1rem;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 15px;
            }
            &.icon-social {
                width: 32px;
                height: 32px;
                padding: 0.25rem;
                border-radius: 50%;
            }
            &:focus, &:hover {
                color: #A7957B;
            }
        }
    }
}

#footer-copyright {
    background-color: $footer-background-color;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid $footer-border-color;
    color: $footer-text-color;

    .copyright {
        margin-bottom: 0rem;
    }
}