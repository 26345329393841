@-webkit-keyframes zoomOut {
    from {
        -webkit-transform: scale(1.5, 1.5);
    }
    to {
        -webkit-transform: scale(1, 1);
    }
}
@keyframes zoomOut {
    from {
        transform: scale(1.5, 1.5);
    }
    to {
        transform: scale(1, 1);
    }
}

#carousel-banner {
    .carousel-item {
        position: relative;

        &::after { content: ""; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background-color: rgba(110, 110, 110, 0.5); mix-blend-mode: multiply; opacity: 0.25; }
    }
    .carousel-caption {
        bottom: 50%;
        transform: translateY(50%);

        .caption-title {
            margin-bottom: 1.5rem;
            font-weight: 600;
            font-size: 1.8rem;

            .text-uppercase {
                font-size: 2rem;
            }
        }
        .caption-button {
            border-radius: 0rem;
            text-transform: uppercase;
        }
    }
}

.banner {
    position: relative;
    padding-left: 0rem;
    padding-right: 0rem;
    overflow: hidden;

    &::after { content: ""; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background-color: rgba(110, 110, 110, 0.0); mix-blend-mode: multiply; opacity: 0.6; }

    .banner-bg { width: 100%; height: auto; animation: zoomOut 15s; }
    .banner-content { position: absolute; left: 0rem; right: 0rem; bottom: 50%; transform: translateY(50%); text-align: center; z-index: 10; }
    .banner-title { text-transform: uppercase; color: #FFFFFF; text-shadow: 0px 3px 12px #00000029; font-size: 60px; font-weight: 700; }
    .banner-subtitle { text-transform: uppercase; color: #FFFFFF; text-shadow: 0px 3px 12px #00000029; font-size: 1.75rem; font-weight: 700; }

    .carousel-control-next,
    .carousel-control-prev { z-index: 10; }
}