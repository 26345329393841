#leadership {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .card-item { display: none; }
    .btn-enroll { display: block; }

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        height: 100%;
    }
    .card-body {
        padding: 1.5rem 0rem;
    }
    .card-number {
        width: 32px;
        height: 32px;
        background-color: #0C2842;
        color: #FFF;
        font-weight: 600;
        text-align: center;
        line-height: 32px;
        margin-bottom: 1rem;
    }
    .card-author {
        margin-bottom: 1rem;
        font-weight: 600;
    }
    .card-link {
        color: #0C2842;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

#testimonial {
    padding-top: 3rem;
    padding-bottom: 3rem;
    
    .swiper-button-next { color: #000; right: 0rem; }
    .swiper-button-prev { color: #000; }

    .card {
        background-color: #0C284212;
        border-radius: 0rem;
        border-width: 0rem;
        position: relative;
        height: 100%;
    }
    .card-corner {
        width: 0; 
        height: 0; 
        border-top: 50px solid #152841;
        border-right: 50px solid transparent;
        
        span {
            position:absolute;
            top: 3px;
            width: 20px;
            left: 5px;
            text-align: center;
            font-size: 24px;
            font-family: arial;
            display: block;
            color: #FFF;
            font-weight: 600;
        }
    }
    .card-body {
        padding: 0.5rem 2rem;
        padding-top: 0rem;
        color: #152841;
    }
}

#news-article.section {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .heading {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;

        .heading-title small {
            text-transform: none;
            color: #575757;
            font-size: 1rem;
        }

        .news-meta {
            font-size: 0.8rem;
            margin-bottom: 1rem;

            .nav-item {
                margin: 0.3rem 0.3rem;
            }
            .nav-link.btn {
                border-radius: 0rem;
                padding: 0.25rem 1rem;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 0.8rem;
            }
            .nav-link.icon-social {
                width: 30px;
                height: 30px;
                padding: 0.25rem;
                border-radius: 50%;
            }
        }
    }
}

#news-related.section {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        height: 100%;
        background-color: transparent;
    }
    .card-img-top {
        border-radius: 0rem;
    }
    .card-title {
        margin-bottom: 0rem;
        text-transform: uppercase;
        font-weight: 600;
    }
    .card-meta {
        color: #ADADAD;
        font-size: 15px;
        margin-bottom: 0rem;
    }
    .card-body {
        padding: 1.5rem 0rem;
        position: relative;
    }
    .card-link {
        border-radius: 0rem;
        text-transform: uppercase;
    }
    .horizontal-line {
        width: 100%;
    }
}