$background-color-blue: #0C2842;

body {
    font-family: source-sans-pro, sans-serif;
    letter-spacing: 0.02rem;
}

.heading {
    .heading-title {
        text-transform: uppercase;
        font-weight: 600;
    }
    .heading-title::after {
        content: "";
        display: block;
        border-bottom: 2px solid #ADADAD;
        width: 50px;
        margin: 1rem 0rem;
    }
    &.center {
        text-align: center;
        
        .heading-title::after {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#features.section {
    .section-inner {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.features {
    .card {
        border-radius: 0rem;
        border-width: 0rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        height: 100%;
    }
    .card-img-top {
        width: 96px;
        height: 96px;
        padding: 1rem;
        background-color: $background-color-blue;
        border-radius: 50%;
        display: block;
        margin: 0rem auto;
    }
    .card-body {
        text-align: center;
        padding: 1.25rem 0rem;

        .card-title { color: #0C2842; font-weight: 600; }
        .card-text { color: #575757; }
        .card-link {
            color: #0C2842;
            text-decoration: underline;
            text-underline-offset: 4px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}

#enroll.section {
    background-color: $background-color-blue;
    margin-bottom: 40px;
    color: #FFFFFF;

    .content-area {
        position: relative;
        padding: 2.5rem 1rem;
        top: 50%;
        transform: translateY(-50%);
    }
    .btn-more {
        text-transform: uppercase;
        border-radius: 0rem;
        font-size: 16px;
        font-weight: 600;
        margin-top: 1.5rem;
        background-color: #ADADAD;
        border-color: #ADADAD;
    }
}

#brochure.section {
    border-bottom: 1px solid #ADADAD;

    .content-area {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .card {
        background-color: #0C284212;
        border-radius: 0rem;
        border-width: 0rem;
        text-align: center;
        position: relative;
        height: 100%;
    }
    .card-corner {
        width: 0; 
        height: 0; 
        border-top: 50px solid #152841;
        border-right: 50px solid transparent;
        
        span {
            position:absolute;
            top: 3px;
            width: 20px;
            left: 5px;
            text-align: center;
            font-size: 16px;
            font-family: arial;
            transform: rotate(-45deg);
            display: block;
            color: #FFF;
            font-weight: 600;
        }
    }
    .card-body {
        padding: 0.5rem 0.5rem;
        padding-top: 0rem;
        color: #152841;
        font-weight: 600;
    }
}

#forum.section {
    padding-top: 3rem;
    padding-bottom: 3rem;

    form {
        background-color: #0C284212;
        padding: 1rem 1rem;
        color: #0C2842;

        .form-control {
            font-style: italic;
            border-radius: 0rem;
        }
        .btn-link {
            text-transform: uppercase;
            color: #0C2842;
            text-decoration: underline;
            text-underline-offset: 3px;
            font-weight: 600;
        }
    }
}

#news.section {
    background-color: #0C284212;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .card-item { display: none; }
    .btn-enroll { display: block; }

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        height: 100%;
        background-color: transparent;
    }
    .card-img-top {
        border-radius: 0rem;
    }
    .card-title {
        margin-bottom: 0rem;
        text-transform: uppercase;
        font-weight: 600;
    }
    .card-meta {
        color: #ADADAD;
        font-size: 15px;
        margin-bottom: 0rem;
    }
    .card-body {
        padding: 1.5rem 0rem;
        position: relative;
    }
    .card-link {
        border-radius: 0rem;
        text-transform: uppercase;
    }
    .horizontal-line {
        width: 100%;
    }
}

/* Parallax */
.parallax-background { min-height: 20rem; background-position: 50% 0; background-repeat: repeat; background-attachment: fixed; background-size: auto; }
.overlay::before { content: ""; position: absolute; left: 0rem; right: 0rem; top: 0rem; bottom: 0rem; background-color: rgba(255, 255, 255, 0.85); }
