.btn-golden {
	background-color: #A7957B;
	color: #FFFFFF;
	text-shadow: 0px 3px 12px #00000029;
	border: 0px;
	border-radius: 0px;
	text-transform: uppercase;
	padding: 2px 30px;
}

.btn-golden:focus, .btn-golden:hover {
	background-color: #FFFFFF;
	color: #A7957B;
}

.btn-outline-golden {
	color: #A7957B;
	border-color: #A7957B;
	border-radius: 0rem;
	text-transform: uppercase;
	padding: 2px 30px;
}

.btn-outline-golden:focus, .btn-outline-golden:hover {
	background-color: #A7957B;
	color: #FFFFFF;
}

.btn-outline-white {
	color: #FFFFFF;
	border-color: #FFFFFF;
	border-radius: 0rem;
	text-transform: uppercase;
	padding: 2px 30px;
}

.btn-outline-white:focus, .btn-outline-white:hover {
	background-color: #FFFFFF;
	color: #A7957B;
}

.btn-outline-black {
	color: #000000;
	border-color: #000000;
	border-radius: 0rem;
	text-transform: uppercase;
	padding: 2px 30px;
}

.btn-outline-black:focus, .btn-outline-black:hover {
	background-color: #000000;
	color: #FFFFFF;
}

@-webkit-keyframes zoomIn {
	from {
		-webkit-transform: scale(1, 1);
	}
	to {
		-webkit-transform: scale(1.5, 1.5);
	}
}

@keyframes zoomIn {
	from {
		transform: scale(1, 1);
	}
	to {
		transform: scale(1.5, 1.5);
	}
}

.carousel ._carousel-inner .carousel-item > img {
	-webkit-animation: zoomIn 30s;
	animation: zoomIn 30s;
}

.my-custom-select-wrapper {
	position: relative;
	user-select: none;
	width: 100%;
}

.my-custom-select-wrapper .my-custom-select {
	position: relative;
	display: flex;
	flex-direction: column;
	border-width: 0 2px 0 2px;
	border-style: solid;
	border-color: #394a6d;
	border: 0px;
}

.my-custom-select-wrapper .my-custom-select__trigger {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 22px;
	font-size: 16px;
	font-weight: 300;
	color: #3b3b3b;
	height: 38px;
	line-height: 60px;
	background: #ffffff;
	cursor: pointer;
	border-width: 2px 0 2px 0;
	border-style: solid;
	border-color: #394a6d;
	border: 0px;
	background-color: #EAE2D6;
}

.my-custom-select-wrapper .custom-options {
	position: absolute;
	display: block;
	top: 100%;
	left: 0;
	right: 0;
	border: 2px solid #394a6d;
	border-top: 0;
	background: #fff;
	transition: all 0.5s;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	z-index: 2;
	border-color: #EAE2D6;
}

.my-custom-select-wrapper .my-custom-select.open .custom-options {
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}

.my-custom-select-wrapper .custom-option {
	position: relative;
	display: block;
	padding: 0 22px 0 22px;
	font-size: 16px;
	font-weight: 300;
	color: #3b3b3b;
	line-height: 38px;
	cursor: pointer;
	transition: all 0.5s;
}

.my-custom-select-wrapper .custom-option:hover {
	cursor: pointer;
	background-color: #b2b2b2;
}

.my-custom-select-wrapper .custom-option.selected {
	color: #ffffff;
	background-color: #305c91;
}

.my-custom-select-wrapper .arrow {
	position: relative;
	height: 15px;
	width: 15px;
}

.my-custom-select-wrapper .arrow::before, .my-custom-select-wrapper .arrow::after {
	content: "";
	position: absolute;
	bottom: 0px;
	width: 0.15rem;
	height: 100%;
	transition: all 0.5s;
}

.my-custom-select-wrapper .arrow::before {
	left: -5px;
	transform: rotate(45deg);
	background-color: #394a6d;
}

.my-custom-select-wrapper .arrow::after {
	left: 5px;
	transform: rotate(-45deg);
	background-color: #394a6d;
}

.my-custom-select-wrapper .open .arrow::before {
	left: -5px;
	transform: rotate(-45deg);
}

.my-custom-select-wrapper .open .arrow::after {
	left: 5px;
	transform: rotate(45deg);
}

#contact-form .form-control {
	border-color: #0C2842;
}

#contact-form .form-control:focus {
	box-shadow: none;
}

.fsBody, .fsForm {
	padding: 0px !important;
}

.fsForm .fsField {
	background-color: #EAE2D6 !important;
	border-radius: 0 !important;
	border: 0 !important;
	height: 35px !important;
}

.fsForm .fsField:focus {
	box-shadow: none !important;
}

.fsForm .fsSubmitButton {
	color: #000000 !important;
	border-color: #000000 !important;
	border-radius: 0rem !important;
	text-transform: uppercase !important;
	padding: 8px 30px !important;
	font-family: 'Karla', sans-serif !important;
	font-size: 16px !important;
	background-color: transparent !important;
}

.fsForm .fsSubmitButton:focus, .fsForm .fsSubmitButton:hover {
	background-color: #000000 !important;
	color: #FFFFFF !important;
}

#contact-form-alert .modal-header {
	border-bottom-width: 0rem;
	padding: 0.1rem 1rem;
}

#contact-form-alert .modal-header .close {
	padding: 0.8rem 0.5rem;
	outline: none;
}

#contact-form-alert .modal-content {
	border-radius: 0rem;
	border-width: 0.5rem;
}

#navigation-top {
	padding-top: 1rem;
}

#navigation-top .nav-item {
	margin: 0.5rem 0.25rem;
}

#navigation-top .nav-link.btn {
	border-radius: 0rem;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
}

#navigation-top .nav-link.icon-social {
	width: 32px;
	height: 32px;
	padding: 0.25rem;
	border-radius: 50%;
}

#navigation {
	background-color: #FFFFFF;
}

#navigation .navbar-brand .img {
	height: 80px;
}

#navigation .navbar-nav .nav-item {
	display: flex;
	align-items: center;
	margin-left: 1.25rem;
}

#navigation .navbar-nav .nav-link {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600;
	color: #0C2842;
}

#navigation .navbar-nav .nav-link:focus,
#navigation .navbar-nav .nav-link:hover {
	color: #999999;
}

#navigation .navbar-nav .nav-link._active {
	border-bottom: 2px solid #0C2842;
}

#navigation .navbar-nav .nav-link.active:hover {
	border-bottom-color: #999999;
}

@-webkit-keyframes zoomOut {
	from {
		-webkit-transform: scale(1.5, 1.5);
	}
	to {
		-webkit-transform: scale(1, 1);
	}
}

@keyframes zoomOut {
	from {
		transform: scale(1.5, 1.5);
	}
	to {
		transform: scale(1, 1);
	}
}

#carousel-banner .carousel-item {
	position: relative;
}

#carousel-banner .carousel-item::after {
	content: "";
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(110, 110, 110, 0.5);
	mix-blend-mode: multiply;
	opacity: 0.25;
}

#carousel-banner .carousel-caption {
	bottom: 50%;
	transform: translateY(50%);
}

#carousel-banner .carousel-caption .caption-title {
	margin-bottom: 1.5rem;
	font-weight: 600;
	font-size: 1.8rem;
}

#carousel-banner .carousel-caption .caption-title .text-uppercase {
	font-size: 2rem;
}

#carousel-banner .carousel-caption .caption-button {
	border-radius: 0rem;
	text-transform: uppercase;
}

.banner {
	position: relative;
	padding-left: 0rem;
	padding-right: 0rem;
	overflow: hidden;
}

.banner::after {
	content: "";
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(110, 110, 110, 0);
	mix-blend-mode: multiply;
	opacity: 0.6;
}

.banner .banner-bg {
	width: 100%;
	height: auto;
	animation: zoomOut 15s;
}

.banner .banner-content {
	position: absolute;
	left: 0rem;
	right: 0rem;
	bottom: 50%;
	transform: translateY(50%);
	text-align: center;
	z-index: 10;
}

.banner .banner-title {
	text-transform: uppercase;
	color: #FFFFFF;
	text-shadow: 0px 3px 12px #00000029;
	font-size: 60px;
	font-weight: 700;
}

.banner .banner-subtitle {
	text-transform: uppercase;
	color: #FFFFFF;
	text-shadow: 0px 3px 12px #00000029;
	font-size: 1.75rem;
	font-weight: 700;
}

.banner .carousel-control-next,
.banner .carousel-control-prev {
	z-index: 10;
}

.widget .widget-title h4 {
	font-size: 18px;
	font-weight: 600;
}

.widget .widget-divider::after {
	content: "";
	display: block;
	border-bottom: 2px solid #ADADAD;
	width: 120px;
	margin: 1.5rem 0rem;
}

.widget .widget-content .btn-more {
	text-transform: uppercase;
	border-radius: 0rem;
	font-size: 16px;
	font-weight: 600;
	margin-top: 1.5rem;
	background-color: #ADADAD;
	border-color: #ADADAD;
}

.widget .widget-content .address a {
	color: #FFFFFF;
	text-decoration: none;
}

#footer {
	background-color: #152841;
	padding-top: 40px;
	padding-bottom: 30px;
	color: #FFFFFF;
}

#footer .nav .nav-link {
	margin-right: 0.8rem;
}

#footer .nav .nav-link.btn {
	border-radius: 0rem;
	padding: 0.25rem 1rem;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 15px;
}

#footer .nav .nav-link.icon-social {
	width: 32px;
	height: 32px;
	padding: 0.25rem;
	border-radius: 50%;
}

#footer .nav .nav-link:focus, #footer .nav .nav-link:hover {
	color: #A7957B;
}

#footer-copyright {
	background-color: #152841;
	text-align: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-top: 1px solid #ADADAD;
	color: #FFFFFF;
}

#footer-copyright .copyright {
	margin-bottom: 0rem;
}

body {
	font-family: source-sans-pro, sans-serif;
	letter-spacing: 0.02rem;
}

.heading .heading-title {
	text-transform: uppercase;
	font-weight: 600;
}

.heading .heading-title::after {
	content: "";
	display: block;
	border-bottom: 2px solid #ADADAD;
	width: 50px;
	margin: 1rem 0rem;
}

.heading.center {
	text-align: center;
}

.heading.center .heading-title::after {
	margin-left: auto;
	margin-right: auto;
}

#features.section .section-inner {
	padding-top: 40px;
	padding-bottom: 40px;
}

.features .card {
	border-radius: 0rem;
	border-width: 0rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	height: 100%;
}

.features .card-img-top {
	width: 96px;
	height: 96px;
	padding: 1rem;
	background-color: #0C2842;
	border-radius: 50%;
	display: block;
	margin: 0rem auto;
}

.features .card-body {
	text-align: center;
	padding: 1.25rem 0rem;
}

.features .card-body .card-title {
	color: #0C2842;
	font-weight: 600;
}

.features .card-body .card-text {
	color: #575757;
}

.features .card-body .card-link {
	color: #0C2842;
	text-decoration: underline;
	text-underline-offset: 4px;
	text-transform: uppercase;
	font-weight: 600;
}

#enroll.section {
	background-color: #0C2842;
	margin-bottom: 40px;
	color: #FFFFFF;
}

#enroll.section .content-area {
	position: relative;
	padding: 2.5rem 1rem;
	top: 50%;
	transform: translateY(-50%);
}

#enroll.section .btn-more {
	text-transform: uppercase;
	border-radius: 0rem;
	font-size: 16px;
	font-weight: 600;
	margin-top: 1.5rem;
	background-color: #ADADAD;
	border-color: #ADADAD;
}

#brochure.section {
	border-bottom: 1px solid #ADADAD;
}

#brochure.section .content-area {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

#brochure.section .card {
	background-color: #0C284212;
	border-radius: 0rem;
	border-width: 0rem;
	text-align: center;
	position: relative;
	height: 100%;
}

#brochure.section .card-corner {
	width: 0;
	height: 0;
	border-top: 50px solid #152841;
	border-right: 50px solid transparent;
}

#brochure.section .card-corner span {
	position: absolute;
	top: 3px;
	width: 20px;
	left: 5px;
	text-align: center;
	font-size: 16px;
	font-family: arial;
	transform: rotate(-45deg);
	display: block;
	color: #FFF;
	font-weight: 600;
}

#brochure.section .card-body {
	padding: 0.5rem 0.5rem;
	padding-top: 0rem;
	color: #152841;
	font-weight: 600;
}

#forum.section {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#forum.section form {
	background-color: #0C284212;
	padding: 1rem 1rem;
	color: #0C2842;
}

#forum.section form .form-control {
	font-style: italic;
	border-radius: 0rem;
}

#forum.section form .btn-link {
	text-transform: uppercase;
	color: #0C2842;
	text-decoration: underline;
	text-underline-offset: 3px;
	font-weight: 600;
}

#news.section {
	background-color: #0C284212;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#news.section .card-item {
	display: none;
}

#news.section .btn-enroll {
	display: block;
}

#news.section .card {
	border-radius: 0rem;
	border-width: 0rem;
	height: 100%;
	background-color: transparent;
}

#news.section .card-img-top {
	border-radius: 0rem;
}

#news.section .card-title {
	margin-bottom: 0rem;
	text-transform: uppercase;
	font-weight: 600;
}

#news.section .card-meta {
	color: #ADADAD;
	font-size: 15px;
	margin-bottom: 0rem;
}

#news.section .card-body {
	padding: 1.5rem 0rem;
	position: relative;
}

#news.section .card-link {
	border-radius: 0rem;
	text-transform: uppercase;
}

#news.section .horizontal-line {
	width: 100%;
}

/* Parallax */
.parallax-background {
	min-height: 20rem;
	background-position: 50% 0;
	background-repeat: repeat;
	background-attachment: fixed;
	background-size: auto;
}

.overlay::before {
	content: "";
	position: absolute;
	left: 0rem;
	right: 0rem;
	top: 0rem;
	bottom: 0rem;
	background-color: rgba(255, 255, 255, 0.85);
}

#leadership {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#leadership .card-item {
	display: none;
}

#leadership .btn-enroll {
	display: block;
}

#leadership .card {
	border-radius: 0rem;
	border-width: 0rem;
	height: 100%;
}

#leadership .card-body {
	padding: 1.5rem 0rem;
}

#leadership .card-number {
	width: 32px;
	height: 32px;
	background-color: #0C2842;
	color: #FFF;
	font-weight: 600;
	text-align: center;
	line-height: 32px;
	margin-bottom: 1rem;
}

#leadership .card-author {
	margin-bottom: 1rem;
	font-weight: 600;
}

#leadership .card-link {
	color: #0C2842;
	text-decoration: underline;
	text-underline-offset: 4px;
	text-transform: uppercase;
	font-weight: 600;
}

#testimonial {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#testimonial .swiper-button-next {
	color: #000;
	right: 0rem;
}

#testimonial .swiper-button-prev {
	color: #000;
}

#testimonial .card {
	background-color: #0C284212;
	border-radius: 0rem;
	border-width: 0rem;
	position: relative;
	height: 100%;
}

#testimonial .card-corner {
	width: 0;
	height: 0;
	border-top: 50px solid #152841;
	border-right: 50px solid transparent;
}

#testimonial .card-corner span {
	position: absolute;
	top: 3px;
	width: 20px;
	left: 5px;
	text-align: center;
	font-size: 24px;
	font-family: arial;
	display: block;
	color: #FFF;
	font-weight: 600;
}

#testimonial .card-body {
	padding: 0.5rem 2rem;
	padding-top: 0rem;
	color: #152841;
}

#news-article.section {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#news-article.section .heading {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}

#news-article.section .heading .heading-title small {
	text-transform: none;
	color: #575757;
	font-size: 1rem;
}

#news-article.section .heading .news-meta {
	font-size: 0.8rem;
	margin-bottom: 1rem;
}

#news-article.section .heading .news-meta .nav-item {
	margin: 0.3rem 0.3rem;
}

#news-article.section .heading .news-meta .nav-link.btn {
	border-radius: 0rem;
	padding: 0.25rem 1rem;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.8rem;
}

#news-article.section .heading .news-meta .nav-link.icon-social {
	width: 30px;
	height: 30px;
	padding: 0.25rem;
	border-radius: 50%;
}

#news-related.section {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#news-related.section .card {
	border-radius: 0rem;
	border-width: 0rem;
	height: 100%;
	background-color: transparent;
}

#news-related.section .card-img-top {
	border-radius: 0rem;
}

#news-related.section .card-title {
	margin-bottom: 0rem;
	text-transform: uppercase;
	font-weight: 600;
}

#news-related.section .card-meta {
	color: #ADADAD;
	font-size: 15px;
	margin-bottom: 0rem;
}

#news-related.section .card-body {
	padding: 1.5rem 0rem;
	position: relative;
}

#news-related.section .card-link {
	border-radius: 0rem;
	text-transform: uppercase;
}

#news-related.section .horizontal-line {
	width: 100%;
}

#sectors {
	background-color: #0C284212;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#sectors .card {
	border-radius: 0rem;
	border-width: 0rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: transparent;
}

#sectors .card-img {
	width: 96px;
	height: 96px;
	padding: 1rem;
	background-color: #0C2842;
	border-radius: 50%;
	text-align: center;
}

#sectors .card-img .card-img-top {
	height: 100%;
	width: auto;
}

#sectors .card-body {
	padding: 1.25rem 0rem;
}

#sectors .card-body .card-title {
	color: #0C2842;
	font-weight: 600;
	text-transform: uppercase;
}

#sectors .card-body .card-text {
	color: #575757;
}

.btn-enroll {
	text-transform: uppercase;
	font-weight: 600;
	padding: 0.5rem 2rem;
	border-radius: 0rem;
}

#masterclass, #series, #training, #advisory {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#masterclass .card, #series .card, #training .card, #advisory .card {
	border-radius: 0rem;
	border-width: 0rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: transparent;
}

#masterclass .card-img, #series .card-img, #training .card-img, #advisory .card-img {
	width: 96px;
	height: 96px;
	padding: 1.5rem;
	background-color: #0C2842;
	border-radius: 50%;
	text-align: center;
	margin: 0rem auto;
}

#masterclass .card-img .card-img-top, #series .card-img .card-img-top, #training .card-img .card-img-top, #advisory .card-img .card-img-top {
	height: 100%;
	width: 100%;
}

#masterclass .card-body, #series .card-body, #training .card-body, #advisory .card-body {
	padding: 1rem 0rem;
	text-align: center;
}

#masterclass .card-body .card-title, #series .card-body .card-title, #training .card-body .card-title, #advisory .card-body .card-title {
	color: #0C2842;
	font-weight: 600;
}

#masterclass .card-body .card-text, #series .card-body .card-text, #training .card-body .card-text, #advisory .card-body .card-text {
	color: #575757;
}

#masterclass .requirements {
	background-color: #15284112;
	padding: 3rem;
	margin-bottom: 1.5rem;
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}

#masterclass .requirements h4 {
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	margin-bottom: 1rem;
}

#masterclass .requirements li {
	margin-bottom: 1rem;
}

#series, #advisory {
	background-color: #15284112;
}

#faqs {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#faqs .accordion .card {
	border-radius: 0rem;
	border-width: 0rem;
	margin-bottom: 1rem;
}

#faqs .accordion .card-header {
	background-color: #0C2842;
	color: #FFF;
	border-bottom: 0rem;
	position: relative;
	cursor: pointer;
}

#faqs .accordion .card-header .card-heading {
	padding-right: 3rem;
	display: block;
}

#faqs .accordion .card-header .card-icon {
	position: absolute;
	text-align: center;
	background-color: #ADADAD;
	width: 3.5rem;
	height: 100%;
	top: 0rem;
	right: 0rem;
	font-size: 1.5rem;
}

#faqs .accordion .card-header .fa-chevron {
	position: relative;
	display: block;
	top: 50%;
	transform: translateY(-50%);
}

#faqs .accordion .card-header .fa-chevron::before {
	content: "\f077";
}

#faqs .accordion .card-header.collapsed .fa-chevron::before {
	content: "\f078";
}

#faqs .accordion .card-body {
	padding: 1rem 0rem;
}

#our-goal .card {
	border-radius: 0rem;
	border-width: 0rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: transparent;
}

#our-goal .card-img {
	width: 64px;
	height: 64px;
	padding: 1rem;
	background-color: #0C2842;
	border-radius: 50%;
	text-align: center;
}

#our-goal .card-img .card-img-top {
	height: 100%;
	width: 100%;
}

#our-goal .card-body {
	padding: 1rem 0rem;
}

#our-goal .card-body .card-title {
	color: #0C2842;
	font-weight: 600;
	text-transform: uppercase;
}

#our-goal .card-body .card-text {
	color: #575757;
}

#our-story {
	background-color: #0C2842;
	padding-top: 3rem;
	padding-bottom: 3rem;
	color: #FFF;
}

#our-team {
	background-color: #0C284212;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#our-team .card {
	border-radius: 0rem;
	border-width: 0rem;
	background-color: transparent;
}

#our-team .card-title {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 20px;
}

#our-team .card-subtitle {
	font-weight: 600;
	font-size: 18px;
	text-transform: capitalize;
}

#our-team .card-link {
	color: #000;
	position: relative;
}

#our-team .card-link .fa {
	width: 20px;
	height: 20px;
	background-color: #000;
	border-radius: 50%;
	color: #FFF;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	position: absolute;
	left: -30px;
	top: 1px;
}

#our-team .card-text {
	margin-top: 1.5rem;
}

#our-team .card-body {
	padding-left: 0rem;
	padding-right: 0rem;
}

#contact {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#contact form .form-control {
	font-style: italic;
	border-radius: 0rem;
}

#contact form .btn-action {
	border-radius: 0rem;
	text-transform: uppercase;
	font-weight: 600;
	padding: 0.25rem 2rem;
	float: right;
}

#contact .widget-content .address a {
	color: #000;
}
