#contact-form-alert {
    .modal-header {
        border-bottom-width: 0rem;
        padding: 0.1rem 1rem;

        .close {
            padding: 0.8rem 0.5rem;
            outline: none;
        }
    }
    .modal-content {
        border-radius: 0rem;
        border-width: 0.5rem;
    }
}