#sectors {
    background-color: #0C284212;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: transparent;
    }
    .card-img {
        width: 96px;
        height: 96px;
        padding: 1rem;
        background-color: $background-color-blue;
        border-radius: 50%;
        text-align: center;

        .card-img-top {
            height: 100%;
            width: auto;
        }
    }
    .card-body {
        padding: 1.25rem 0rem;

        .card-title {
            color: #0C2842;
            font-weight: 600;
            text-transform: uppercase;
        }
        .card-text { color: #575757; }
    }
}