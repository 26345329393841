.my-custom-select-wrapper {
    position: relative;
    user-select: none;
    width: 100%;

    .my-custom-select {
        position: relative;
        display: flex;
        flex-direction: column;
        border-width: 0 2px 0 2px;
        border-style: solid;
        border-color: #394a6d;
        border: 0px;
    }
    .my-custom-select__trigger {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        font-size: 16px;
        font-weight: 300;
        color: #3b3b3b;
        height: 38px;
        line-height: 60px;
        background: #ffffff;
        cursor: pointer;
        border-width: 2px 0 2px 0;
        border-style: solid;
        border-color: #394a6d;
        border: 0px;
        background-color: #EAE2D6;
    }
    .custom-options {
        position: absolute;
        display: block;
        top: 100%;
        left: 0;
        right: 0;
        border: 2px solid #394a6d;
        border-top: 0;
        background: #fff;
        transition: all 0.5s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        z-index: 2;
        border-color: #EAE2D6;
    }
    .my-custom-select.open .custom-options {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
    .custom-option {
        position: relative;
        display: block;
        padding: 0 22px 0 22px;
        font-size: 16px;
        font-weight: 300;
        color: #3b3b3b;
        line-height: 38px;
        cursor: pointer;
        transition: all 0.5s;
    }
    .custom-option:hover {
        cursor: pointer;
        background-color: #b2b2b2;
    }
    .custom-option.selected {
        color: #ffffff;
        background-color: #305c91;
    }

    .arrow {
        position: relative;
        height: 15px;
        width: 15px;
   }
    .arrow::before, .arrow::after {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 0.15rem;
        height: 100%;
        transition: all 0.5s;
   }
    .arrow::before {
        left: -5px;
        transform: rotate(45deg);
        background-color: #394a6d;
   }
    .arrow::after {
        left: 5px;
        transform: rotate(-45deg);
        background-color: #394a6d;
   }
    .open .arrow::before {
        left: -5px;
        transform: rotate(-45deg);
   }
    .open .arrow::after {
        left: 5px;
        transform: rotate(45deg);
   }
}

#contact-form {
    .form-control {
        border-color: #0C2842;

        &:focus {
            box-shadow: none;
        }
    }
}

.fsBody, .fsForm {
    padding: 0px !important;
}
.fsForm {
    .fsField {
        background-color: #EAE2D6 !important;
        border-radius: 0 !important;
        border: 0 !important;
        height: 35px !important;

        &:focus {
            box-shadow: none !important;
        }
    }
    .fsSubmitButton {
        color: #000000 !important;
        border-color: #000000 !important;
        border-radius: 0rem !important;
        text-transform: uppercase !important;
        padding: 8px 30px !important;
        font-family: 'Karla', sans-serif !important;
        font-size: 16px !important;
        background-color: transparent !important;
    
        &:focus, &:hover {
            background-color: #000000 !important;
            color: #FFFFFF !important;
        }
    }
}